import React, { Suspense } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  useParams
} from "react-router-dom";

const { useState, useRef, useEffect } = React

function Registration ({t}) {

  const input = useRef(null);
  const alert_danger_element = useRef(null);
  const alert_success_element = useRef(null);

  const [number, set_number] = useState(0)
  const [numberCheck, set_numberCheck] = useState(false)
  const [numberPlace, set_numberPlace] = useState(t('phone.1'))
  const [password, set_password] = useState('')
  const [passwordConfirm, set_passwordConfirm] = useState('')
  const [smscode, set_smscode] = useState(0)
  const [smsSendTimer, set_smsSendTimer] = useState(0)
  const [checkBox, set_checkBox] = useState(false)
  const [inputsToggle, set_inputsToggle] = useState('phone')
  const [alert, set_alert] = useState('')
  const [success_alert, set_success_alert] = useState('')
  const [loader, set_loader] = useState(false)

  useEffect(() => {
    if (!input.current) return
    numberCheck ? input.current.style.color = '#017ab9' : input.current.style.color = '#dd2b0e'
  }, [numberCheck])

  useEffect(() => {
    if (alert) {
      alert_danger_element.current.innerHTML = alert
    }
  }, [alert])

  useEffect(() => {
    if (success_alert) {
      alert_success_element.current.innerHTML = success_alert
    }
  }, [success_alert])

  useEffect((t) => {
    if (smsSendTimer === 0) return
    smsSendTimer > 0 && setTimeout(() => set_smsSendTimer(smsSendTimer - 1), 1000);
  }, [smsSendTimer]);

  function handleClick(e, target) {
    e.stopPropagation();
    if (target === 'number') {
      set_numberPlace(t('phone.2'))
    } else {
      set_numberPlace(t('phone.1'))
    }
  }

  function handleChange(event) {
    if (event.target.name === 'phone' && event.target.value.length > 8) {
      fetch('./api/checknumber/'+event.target.value, {
        method: 'GET',
      })
      .then((response) => {
        if (response.status === 200) {
          set_number(event.target.value);
          set_numberCheck(true);
          set_alert('')
          return;
        } else {
          set_numberCheck(false);
          set_alert(t('msg_invalid_number'))
          set_success_alert('')
          return;
        }
      })
    } else if (event.target.name === 'phone' && event.target.value.length < 9) {
      set_numberCheck(false)
      set_alert('')
      set_success_alert('')
    }

    if ((event.target.name === 'password' || event.target.name === 'password2') && !event.target.value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,30}$/)) {
      event.target.style = 'color: red'
      if (event.target.name === 'password') {
        set_password(event.target.value)
      }
      if (event.target.name === 'password2') {
        set_passwordConfirm(event.target.value)
      }
    } else if ((event.target.name === 'password' || event.target.name === 'password2') && event.target.value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,30}$/)) {
      event.target.style = ''
      if (event.target.name === 'password') {
        set_password(event.target.value)
      }
      if (event.target.name === 'password2') {
        set_passwordConfirm(event.target.value)
      }
    }

    if (event.target.name === 'smscode') {
      set_smscode(event.target.value);
    }

    if (event.target.name === 'agree') {
      set_checkBox(event.target.checked);
    }

    if (alert) {
      set_alert('')
      set_success_alert('')
    }
  }

  function handleSubmit(event, resend) {
    event.preventDefault();
    if (!numberCheck) {
      set_alert(t('msg_invalid_number'))
      set_success_alert('')
      return;
    }
    
    // Password validate
    if(!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,30}$/) || !passwordConfirm.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,30}$/)) {
      set_alert(t('msg_invalid_password2'))
      set_success_alert('')
      set_loader(false)
      return;
    }
    if (password !== passwordConfirm || passwordConfirm.length < 6 || password.length < 6) {
      set_alert(t('msg_invalid_password'))
      set_success_alert('')
      set_loader(false)
      return;
    }
    
    if (!checkBox) {
      set_alert(t('msg_reg_terms'))
      set_success_alert('')
      return;
    }
    if (smsSendTimer !== 0) {
      set_alert(t('msg_sms_resend_stoper') + ': ' + smsSendTimer + 's')
      set_success_alert('')
      return;
    }

    set_loader(true)

    fetch('./api/register/'+number, {
        method: 'POST',
        body: JSON.stringify({
          'password': password,
          'password-confirm': passwordConfirm
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status === 201) {
          set_loader(false)
          set_inputsToggle("sms")
          if (resend) {
            set_success_alert(t('msg_sms_resend'))
            set_smsSendTimer(60)
          } else {
            set_alert('')
            set_success_alert(t('msg_sms_sended') + ': ' + number)
          }
          return;
        } else {
          set_loader(false)
          set_alert(t('msg_send_error'))
          set_success_alert('')
          set_inputsToggle("sms")
          return;
        }
      });
  }

  function handleSms(text) {
    if (text === 'back') {
      set_inputsToggle("phone")
      set_alert('')
      set_success_alert('')
      set_checkBox(false)
      return
    } else if (text === 'send' && smscode && number) {

      set_loader(true)
      set_inputsToggle("")
      set_alert('')
      set_success_alert('')

      fetch('./api/confirm/'+number, {
        method: 'POST',
        body: JSON.stringify({
          'smscode': smscode
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status === 201) {
          set_loader(false)
          set_inputsToggle('')
          set_alert('')
          set_success_alert(t('msg_acc_created'))
          return;
        } else {
          set_loader(false)
          set_smscode(0)
          set_inputsToggle("sms")
          set_alert(t('msg_wrong_smscode'))
          set_success_alert('')
          return;
        }
      })
    } else {
      set_smscode(0)
      set_alert(t('msg_empty_smscode'))
      set_success_alert('')
      return;
    }
  }

  function iosWebView () {
    var standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

    if(ios) {
      if (!standalone && safari) {
        //browser
        return false
      } else if (standalone && !safari) {
        //standalone
        return true
      } else if (!standalone && !safari) {
        //uiwebview
        return true
      }
    } else {
      //not iOS
      return false
    }
  }
  
  if (iosWebView()) {
    return (
      <div className="container dashboard pt-md-5" onClick={(e) => {handleClick(e, 'body')}}>
        <div className="row justify-content-center f-OLNEYb f-up">
          <div className="col-12">
            <div className="dashboard-title w-100 text-center mt-5">
              {t('newReg')}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="container dashboard pt-md-5" onClick={(e) => {handleClick(e, 'body')}}>
        <div className="row justify-content-center f-OLNEYb f-up">
          <div className="col-12">
            <div className="dashboard-title w-100 text-center mt-4"  id="loader">
              {loader ? <Loader /> : null}
            </div>
            <form name="form-register" className="d-flex flex-column align-items-center" onSubmit={handleSubmit} autoComplete="off">
              { 
                inputsToggle === "phone" ?
                  <React.Fragment>
                    <Inputs t={t} handleChange={handleChange} type="tel" placeholder={numberPlace} name="phone" input={input} handleClick={(e) => {handleClick(e, 'number')}} />
                    <Inputs t={t} handleChange={handleChange} type="password" placeholder={t('password.1')} name="password" />
                    <div className="alert fs-18 p-0 border-0 mb-2" style={{color: '#888', alignSelf: 'flex-start'}} role="alert" id="form-register-alert">
                      {t('msg_password_text')}
                      <ul className="pl-4 mb-0">
                        <li>{t('msg_password_validator')}</li>
                      </ul>
                    </div>
                    <Inputs t={t} handleChange={handleChange} type="password" placeholder={t('password.2')} name="password2" />
                    <CheckBox t={t} handleChange={handleChange} />
                  </React.Fragment>
                : inputsToggle === "sms" ?
                    <InputSms t={t} handleChange={handleChange} handleSubmit={handleSubmit} type="text" placeholder={t('smsCode')} name="smscode" />
                : null
              }
              {
                alert ? <div className="alert fs-22 alert-danger mb-2" role="alert" id="form-register-alert" ref={alert_danger_element}></div> : null
              }
              {
                success_alert ? <div className="alert fs-22 alert-success mb-2" role="alert" id="form-register-alert-success" ref={alert_success_element}></div> : null
              }
              
              { inputsToggle === "phone" ? 
                <div className="border-0 choose-user-buttons m-auto w-100 finish-btn-wrapper" id="reg_submit">
                    <div className="row pt-md-1">
                        <div className="row d-flex justify-content-center flex-column m-auto">
                            <button type="Submit" id="finish-registration" className="btn w-100 text-white bg-silk px-md-5 py-md-2 ml-md-2 d-flex mt-md-0 justify-content-center">
                                {t('submit')}
                            </button>
                        </div>
                    </div>
                </div>
                : null 
              }
              { inputsToggle === "sms" ? 
                <div className="border-0 choose-user-buttons m-auto w-100 finish-btn-wrapper" id="sms_submit">
                  <div className="row pt-md-1">
                    <div className="row d-flex justify-content-center flex-column ml-auto mr-2">
                      <button onClick={e => handleSms('back')} type="button" id="finish-registration" className="btn w-100 text-white bg-silk px-md-5 py-md-2 mb-2 d-flex mt-md-0 justify-content-center">
                        <div className="backIcon"></div>
                      </button>
                    </div>
                    <div className="row d-flex justify-content-center flex-column mr-auto ml-2">
                      <button onClick={e => handleSms('send')} type="button" id="finish-registration" className="btn w-100 text-white bg-silk px-md-5 py-md-2 mb-2 d-flex mt-md-0 justify-content-center">
                        {t('registration')}
                      </button>
                    </div>
                  </div>
                </div>
                : null
              }
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const Reg = withTranslation()(Registration);

// page uses the hook
function Page() {
  const { i18n } = useTranslation();
  const locale = useParams()['locale']

  i18n.changeLanguage(locale);

  return (
    <Reg />
  );
}

const Inputs = (props) => (
  <label className="w-100">
    <input
      className="col-12 form-control font-weight-bold d-flex w-100 m-auto"
      autoComplete="off"
      minLength={props.minLength}
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      required
      onChange={props.handleChange}
      ref={props.input}
      onClick={props.handleClick}
    />
  </label>
)

const InputSms = (props) => (
  <label className="w-100 position-relative">
    <input
      className="col-12 form-control font-weight-bold d-flex w-100 m-auto"
      autoComplete="off"
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      required
      onChange={props.handleChange}
      ref={props.input}
    />
    <div
      className="smsResend"
      onClick={e => props.handleSubmit(e, true)}
    ></div>
  </label>
)

const CheckBox = (props) => (
  <label className="checkbox-custom text-silk text-left mt-3 pb-2" id="checkbox">
    <div className="text-chbox">
      <span>{props.t('terms.1')}</span>
      <a href={"/" + useParams()['locale'] + "/terms"} target="_blank" rel="noreferrer">
          {props.t('terms.2')}
      </a>
    </div>
    <input type="checkbox" name="agree" onChange={props.handleChange} />
    <span className="checkmark-chbox"></span>
  </label>
)

// loading component for suspense fallback
const Loader = () => (
  <div className="lds-spinner">
    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
  </div>
);

// here app catches the suspense from page in case translations are not yet loaded
export default function RegWN() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
    </Suspense>
  );
}